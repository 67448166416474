.navbar {
    flex-direction: column;
    background: #06c7fe;
}

nav.fixed-top .navbar-toggler span {
    color: #fff;
}

.social-mobile-wrapper {
    display: none;
}

.dropdown-menu {
    left: auto;
    right: 0;
    border-radius: 0 0 4px 4px !important;
    overflow: hidden;
}

button.pelum-btn:disabled {
    background: #797979 !important;
}

.social-links ul {
    display: flex;
    margin-left: auto;
}

.social-links ul li {
    margin-left: 5px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0273D9;
    overflow: hidden;
}

.navbar.fixed-top {
    position: fixed;
    background: #06c7fe;
    padding-top: 5px;
    padding-bottom: 5px;
}

.navbar.fixed-top .social-links ul li {
    background: #0273d9;
}

nav.navbar .nav-item .nav-link, nav.fixed-top .nav-item .nav-link {
    color: #fff !important;
    font-family: 'Vag Rounded Bold', cursive;
    font-size: 20px;
    text-decoration: none !important;
    font-weight: lighter;
}

nav.navbar .nav-item .nav-link.active, nav.navbar.fixed-top .nav-item .nav-link.active {
    color: #fff !important;
}

.carousel-control i {
    font-size: 36px;
    color: #fff;
    text-shadow: 0px 0px 4px #4e4e4e57;
}

.carousel-control {
    width: 100px;
}

.social-links ul li a {
    display: flex;
}

.social-links p {
    color: #fff;
    font-family: 'Vag Rounded Bold', cursive;
    font-size: 20px;
}

.social-links ul li a i {
    font-size: 14px;
}

.social-links ul li img {
    transform: rotate(-45deg);
    width: 30px;
    height: 30px;
    object-fit: cover;
}

.country {
    position: relative;
    color: #0273D9;
    font-family: 'Vag Rounded Bold',cursive;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.selected-country {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1px 4px 0;
    border-radius: 8px;
    border: 2px solid #0273d9;
    background: #fff;
    width: 100%;
}

.countries {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 24px;
    background: #fff;
    padding: 1px 4px;
    border: 2px solid #0273d9;
    border-top: 0;
    border-radius: 0 0 8px 8px;
    z-index: 9;
    transition: 0.2s ease;
}

.countries a {
    display: block;
    width: 78px;
    color: #0273d9;
}

.countries a:hover {
    opacity: 0.7;
}

.country:hover .countries {
    opacity: 1;
    pointer-events: all;
}

.navbar.fixed-top .social-links ul li svg,
.navbar .social-links ul li svg {
    color: #fff;
}

.row.watch img {
    width: 75%;
    margin: 0 auto;
}

.characters {
    position: relative;
    width: 100%;
    text-align: center;
    background: url("/site/img/characters-bg.jpg");
    overflow: hidden;
    background-size: contain;
}

.characters-container {
    position: absolute;
    bottom: 0;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    width: 100%;
    padding: 20px;
    font-size: 0;
}

.character-area button {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.character-area {
    position: relative;
    display: inline-block;
    vertical-align: bottom;
    padding: 0 28px 40px;
}

.characters-banner {
    position: relative;
    max-width: 928px;
    height: 368px;
    margin: 0 auto;
}

/*.character-area img {
    width: 70%;
}*/

.nuvem {
    height: 65px;
    position: absolute;
}

#nuvem-1 {
    left: 210px;
}

#nuvem-2 {
    right: 215px;
    top: 10px;
}

.characters-banner > img.next-char, .characters-banner > img.prev-char {
    color: #fff;
    position: absolute;
    margin: auto 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}

.characters-banner > img.prev-char {
    left: -40px;
}

.characters-banner > img.next-char {
    right: -40px;
}

.characters-banner > img.prev-char img {
    transform: translateX(-1px);
}

.characters-banner > img.next-char img {
    transform: translateX(2px);
}

.pelum-footer-area .text-center {
    background: url(/site/img/footer-bg.png);
    background-size: unset;
}

.character-area button {
    width: 20px;
    height: 20px;
    margin: 10px auto 0 auto;
    background: #0273d9;
    color: #fff;
    border: 0;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;
}

.character-area button .fa-plus,
.character-area button .fa-minus{
  width: 10px;
  height: 10px;
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.character-area button.active .fa-plus {
  display: none;
}

.character-area button img {
    width: 10px;
    position: absolute;
    left: 5px;
}

.character-description-wrapper {
    background: #5fc03f;
    position: relative;
    padding-bottom: 20px;
}

.character-description .character-text {
    background: #fff;
    padding: 10px 40px;
}

.character-description {
    max-width: 928px;
    margin: 0 auto;
    text-align: initial;
    display: none;
    padding-bottom: 26px;
    border-radius: 24px;
    overflow: hidden;
    background: #fff;
}


.character-description .character-text h3 {
    font-size: 1.5rem;
}

.character-gifts {
    background: #93e0f4;
    padding: 10px 40px;
    text-align: center;
}

.character-gifts h3 {
    margin: 0 10px;
    font-size: 1.2rem;
    border-bottom: 2px solid #fff;
    display: inline;
}

.gifts {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.gifts .gift {
    padding: 10px;
    width: 250px;
}

.character-arrow {
    font-size: 80px;
    color: #fff;
    position: absolute;
    display: none;
    top: -5px;
}

.character-arrow i {
    line-height: 20px;
}

.carousel-indicators li {
    height: 12px;
    width: 12px;
    margin: 0 10px;
    border-radius: 100px;
    background: #17c0fb;
}

.carousel-indicators .active {
    background: #e00019;
}

div#carouselExampleIndicators {
    padding-bottom: 38px;
    background: #fff;
}

.characters::-webkit-scrollbar {
    height: 10px;
}

.characters::-webkit-scrollbar-track {
    background-color: #8dc11f;
}

.characters::-webkit-scrollbar-thumb {
    background-color: #445d0f;
}

.characters-area {
    background: #8ec220;
}

.pelum-video-area .single-feature img {
    height: 75px;
    max-width: initial;
    width: initial;
    display: block;
}

.pelum-video-area .single-feature {
    width: 100%;
    margin: 15px 0 !important;
}

.form-select select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
}

.form-select .form-control {
    outline: none;
}

.form-select .form-control:focus {
    box-shadow: none;
    border: 1px solid #2171b7 !important;
}

.form-select {
    position: relative;
}

.form-select img {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto 10px auto;
}

.footer-links ul {
    display: flex;
    flex-direction: column;
}

.footer-links ul li {
    margin: 0 10px;
}

.footer-links ul li a {
    color: #000;
    text-decoration: none;
}

.footer-copyright p {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    color: #1096e7;
}

.characters-itens p {
    color: #000;
    text-align: center;
}

.boxes {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.characters-itens .box {
    width: 50px;
    height: 50px;
    background: #000;
    margin: 0 auto;
    border-radius: 50%;
    margin-bottom: 10px;
}

.box-container {
    margin: 10px;
}

.dropdown-item.active, .dropdown-item:active {
    color: #2171b7 !important;
    text-decoration: none;
    background: transparent !important;
}

.dropdown-menu.show a:hover {
    border-radius: 0.25rem !important;
}

.dropdown-menu.show {
    background: #08a7d4;
    border: 0;
    padding: 0;
    text-align: center;
    box-shadow: none !important;
}

.dropdown-menu.show a {
    color: #fff;
    font-weight: bold;
    font-family: 'Vag Rounded Bold', cursive;
}

.dropdown-item:hover, .dropdown-item:focus {
    background: #06c7fe;
}

/* ABOUT AREA */

.pelum-about-area {
    /*max-width: 1000px;*/
    margin: 0 auto;
}
.about-text h2 {
    font-size: 1.6rem;
}

.about-text p {
    font-size: 1rem;
}
.about-container {
    display: flex;
}

.about-container .about-text {
    padding: 0px 25px;
    min-width: 380px;
    max-width: 380px;
}

.about-container .about-image {
    position: relative;
}

.about-image {
    height: 500px;
}

.about-image img:last-child {
    object-fit: cover;
    height: 500px;
    width: 100%;
}

.about-text:nth-child(1) h2 {
    padding-bottom: 90px;
}

.about-container:nth-child(1) {
    align-items: flex-end;
}

.about-container:nth-child(1) .about-character {
    position: absolute;
    top: -55px;
    right: -200px;
    width: 300px;
}

.about-container:nth-child(2) {
    align-items: center;
    text-align: right;
}

.about-container:nth-child(2) .about-character {
    position: absolute;
    bottom: -14px;
    left: -125px;
    width: 112px;
}

.about-container:nth-child(3) {
    align-items: center;
}

.about-container:nth-child(3) .about-character {
    position: absolute;
    bottom: -14px;
    right: -10px;
    width: 165px;
}

.form-heading {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
}

.contact-characters {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 50px;
}

.form-heading img {
    height: 130px;
}
.form-heading img:first-child {
    margin-right: 10px;
}
.form-heading img:last-child {
    position: relative;
    left: -15px;
}

.form-heading img:last-child {
  margin-left: 30px;
}

form.subscribe-form {
    max-width: 730px;
    margin: 0 auto;
}

.contact-form small {
    color: #000;
}

.contact-form form input,
.contact-form form textarea {
    color: #0273D9;
    background: #DBF2FF;
    border-color: #90BFF6;
}

form#contact-form .fa-exclamation {
    font-size: 10px;
    width: 15px;
    height: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #0271d2;
    border-radius: 50%;
    color: #0271d2;
    padding: 1px;
}
form#contact-form .fa-exclamation:before {
    padding-left: 1px;
    padding-top: 1px;
}

button.sanrio-button {
    width: 120px;
    height: 30px;
    font-family: 'Vag Rounded Bold', cursive;
    background: #0271d2;
    color: #fff;
    border: 0;
    border-radius: 9px;
    padding: 0px 30px;
    position: relative;
    margin-top: 16px;
    margin-right: 12px;
    cursor: pointer;
}

button.sanrio-button:disabled {
    filter: grayscale(1);
    cursor: not-allowed;
}

button.sanrio-button::after {
    content: '';
    background: url(/site/img/button-icon.png) no-repeat;
    background-size: contain;width: 40px;
    height: 40px;
    position: absolute;
    top: -16px;
    right: -12px;
}

.text-blue {
    color: #07C7FF !important;
}

.text-blue-dark {
    color: #0273D9 !important;
}

.text-dark {
    color: #000 !important;
}

.text-red {
    color: #DF0521 !important;
}

.text-gray {
    color: #808080 !important;
}

.pelum-subscribe-area {
    background: #93e0f4;
}

section.pelum-subscribe-area > .container {
    position: relative;
}

section.pelum-subscribe-area > .container::before {
    content: '';
    position: absolute;
    background: url(/site/img/newsletter-char-1.png) no-repeat;
    background-size: contain;
    width: 92px;
    height: 130px;
    bottom: 40px;
    left: 46px;
}

section.pelum-subscribe-area > .container::after {
    content: '';
    position: absolute;
    background: url(/site/img/newsletter-char-2.png) no-repeat;
    background-size: contain;
    width: 150px;
    height: 247px;
    bottom: 40px;
    right: 0px;
}

.contact-form form input,
.contact-form form textarea,
.contact-form form select {
    font-family: 'Vag Rounded Bold';
    color: #0271d2 !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

}

.contact-form form input::placeholder,
.contact-form form textarea::placeholder {
    color: #0271d2 !important;
}

#cookie-consent {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #93e0f4;
    box-shadow: 1px 1px 9px #0000002e;
    z-index: 99999999;
}

#cookie-consent img {
    height: 100px;
    margin-right: 20px;
}

#cookie-consent .cookie-btn{
  font-family: 'Vag Rounded Bold', cursive;
  background: #0271d2;
  color: #fff;
  border: 0;
  border-radius: 9px;
  padding: 2px 30px;
  margin-top: 16px;
  margin-right: 12px;
  cursor: pointer;
  display: inline-block;
}
#cookie-consent p {
    font-size: 14px;
    color: #000;
    max-width: 750px;
    line-height: 20px;
}

.modal.site-terms h3 {
    font-size: 1.6rem;
    font-weight: bold;
    font-family:'Vag Rounded Bold';
    color: #DF0521 !important;
}

.modal.site-terms p {
    font-size: 1rem;
    margin: 10px 0;
    font-family: 'Vag Rounded Bold', cursive !important;
    color: #808080 !important;
}

.modal.site-terms h3:not(:first-child) {
    margin-top: 30px;
}

.modal.site-terms li {
    list-style: lower-roman;
    padding-left: 20px;
    margin-left: 30px;
    margin-bottom: 10px;
    color: #434a7e;
    line-height: 26px;
    font-size: 1em sans-serif;
    font-family: 'Vag Rounded Bold', cursive !important;
    color: #808080 !important;
}

.modal.site-terms.privacy li {
    list-style: decimal;
    font-size: 1em sans-serif;
    font-family: 'Vag Rounded Bold', cursive !important;
    color: #808080 !important;
}

#redirect-modal .modal-content {
    flex-direction: revert;
    align-items: center;
    border-radius: 0;
    border: 0;
    background: #93e0f4;
}

#redirect-modal .modal-footer {
    border: 0;
    justify-content: space-between;
    padding: 0 15px 12px;
}

#redirect-modal .modal-content .modal-body p {
    line-height: 19px;
}

#redirect-modal .modal-content .modal-footer p {
    margin-top: 12px;
}

#redirect-modal .modal-content .modal-body p:last-child {
    word-break: break-word;
}

button#cancel-redirect-button {
    position: absolute;
    top: 4px;
    right: 8px;
    cursor: pointer;
}

select.mobile-country {
    padding: 6px 2px 5px;
    border-radius: 8px;
    border: 2px solid #0273d9;
    background: #fff;
    color: #0273D9;
    font-family: 'Vag Rounded Bold',cursive;
    width: 90px;
    margin: 10px 0;
}
.gift-tabs {
  cursor: pointer;
}
.gift-tabs.active {
  color: #fff !important;
}

@media (max-width: 1290px) {
    form.subscribe-form {
        max-width: 660px;
    }
    section.pelum-subscribe-area > .container::before {
        left: -30px;
    }

    section.pelum-subscribe-area > .container::after {
        right: -50px;
    }
}

@media (max-width: 991px) {
    .dropdown-menu.show {
      text-align: center;
    }
    form.subscribe-form {
        max-width: 480px;
    }

    .about-container {
        flex-direction: column;
        align-items: center !important;
        justify-content: center;
    }

    .about-container:nth-child(1) .about-character {
        top: -64px;
        right: 14px;
    }

    .about-container:nth-child(2) .about-character {
        left: 10px;
    }

    .about-container:nth-child(3) .about-character {
        right: auto;
        left: 18px;
    }

    .about-container .about-text {
        max-width: none;
        min-width: auto;
        margin-top: 40px;
    }

    div#navbarCollapse {
        position: absolute;
        z-index: 9999;
        left: 0;
        top: 70px;
        width: 100%;
        background: #06c7fe;
        text-align: center;
    }

    nav.navbar .nav-item .nav-link,
    nav.fixed-top .nav-item .nav-link {
        font-size: 1rem;
    }

    .social-links {
        display: none;
    }
    .info-invert {
        flex-direction: row;
    }

    .info {
        text-align: center;
    }

    .about-container {
        margin: 0 !important;
    }

    .about-container:nth-child(2) {
        flex-direction: column-reverse;
        text-align: center;
        margin: 60px 0 !important;
    }
}

.hello-kitty-plane {
    max-width: 340px;
    width: 80%;
    margin: 0 auto;
}

body {
    padding-top: 99px;
    overflow-x: hidden;
}

@media (max-width: 1190px) {
    body {
        padding-top: 69px;
    }

    .navbar-brand.logo img {
        height: 50px;
    }
}

@media (max-width: 1280px) {
    .characters-banner {
        max-width: 567px;
    }

    #nuvem-1 {
        left: 45px;
    }

    #nuvem-2 {
        right: 45px;
    }

}

@media (max-width: 991px) {
    .form-heading img {
      height: 60px;
    }
    .social-mobile-wrapper {
        display: block;
    }

    .social-container-mobile {
        display: flex;
        flex-direction: column;
        padding: 10px 0;
    }

    .social-container-mobile p {
        font-family: 'Vag Rounded Bold', cursive;
        color: #fff;
    }

    .social-container-mobile ul {
        display: flex;
        gap: 15px;
        justify-content: center;
    }

    .social-container-mobile ul li {
        margin-left: 5px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #0273D9;
        color: #fff;
        overflow: hidden;
    }
}

@media (max-width: 767px) {
    .gifts p {
        font-size: 0.5rem;
    }

    .characters-banner > img.next-char, .characters-banner > img.prev-char {
        top: 315px;
    }

    .characters-banner {
        max-width: 380px;
    }

    .character-area {
        width: 100%;
        padding: 0 0px 40px;
    }

    .character-area img {
        max-width: none;
    }

    #nuvem-1 {
        left: 10px;
    }

    #nuvem-2 {
        right: 10px;
    }

    .characters-banner > img.prev-char {
        left: 20%;
    }

    .characters-banner > img.next-char {
        right: 20%;
    }

    h2 {
        font-size: 1.4rem !important;
    }

    .hello-kitty-plane {
        max-width: 200px;
    }

    .about-image {
        height: 300px;
    }
    .about-image img:last-child {
        height: 300px;
        margin-bottom: 100px;
    }

    section.pelum-subscribe-area > .container::before {
        display: none;
    }

    section.pelum-subscribe-area > .container::after {
        display: none;
    }
    div#navbarCollapse {
        top: 60px;
    }

    .about-container:nth-child(1) .about-character {
        top: -30px;
        right: 14px;
        width: 195px;
    }

    .about-container:nth-child(2) .about-character {
        left: 10px;
        width: 70px;
    }

    .about-text:nth-child(1) h2 {
        padding-bottom: 0px;
    }

    .about-container:nth-child(3) .about-character {
        right: auto;
        left: 18px;
        width: 98px;
    }

    body {
        padding-top: 59px;
    }

    .navbar-brand.logo img {
        height: auto;
    }

    div#cookie-consent {
        flex-direction: column;
    }

    #cookie-consent img {
        margin: 0;
    }

    div#cookie-consent {
        text-align: center;
    }

    div#cookie-consent img {
        height: 45px;
    }
}

/*PAINEL*/
.sobre_titulo_2 p, .sobre_titulo_2 span {
    color: #DF0521 !important;
    font-size: 1.4rem;
    font-family: 'Vag Rounded Bold', cursive !important;
}

.descricao-galeria p, .descricao-galeria span {
    color: #808080;
    font-family: 'Vag Rounded Bold', cursive !important;
}

.contato-descricao p {
  line-height: 18px;
}

.modal-body li {
  list-style: auto;
  margin-bottom: 14px;
  padding-left: 20px;
  margin-left: 30px;
  font-family: 'Vag Rounded Bold', cursive;
}
.modal-body, .modal-body p, .modal-body span, .modal-body a, .modal-body h1, .modal-body h2, .modal-body h3, .modal-body h4, .modal-body h5, .modal-body h6 {
  font-family: 'Vag Rounded Bold', cursive !important;
}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {
	.navbar .nav-item .dropdown-menu{ display: none; }
	.navbar .nav-item:hover .nav-link{   }
	.navbar .nav-item:hover .dropdown-menu{ display: block; }
	.navbar .nav-item .dropdown-menu{ margin-top:0; }
  .dropdown-item { font-size: 20px; }
}
/* ============ desktop view .end// ============ */

.dropdown-menu {
  padding: 0;
  left: 0px;
  border-radius: 0;
  border: 0;
}

.dropdown-item {
  padding: 2px 10px;
  color: #fff !important;
  font-family: 'Vag Rounded Bold', cursive;
  transition: opacity 0.3s ease;
}

.dropdown-item:hover {
  opacity: 0.8;
}

.dropdown-toggle::after {
  display: none !important;
}
