@font-face{
    font-family:'Cooper Black';
    src:url('../fonts/cooper-black.ttf');
}

@font-face{
    font-family:'Helvetica Neue';
    src:url('../fonts/HelveticaNeue-Light.ttf');
}

@font-face{
    font-family:'Vag Rounded Black';
    src:url('../fonts/VAGRoundedStd-Black.otf');
}

@font-face{
    font-family:'Vag Rounded Bold';
    src:url('../fonts/VAGRoundedStd-Bold.otf');
}

@font-face{
    font-family:'Vag Rounded Light';
    src:url('../fonts/VAGRoundedStd-Light.otf');
}

@font-face{
    font-family:'Vag Rounded Thin';
    src:url('../fonts/VAGRoundedStd-Thin.otf');
}

.font-helvetica-neue, .font-helvetica-neue p, .font-helvetica-neue p span {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.font-cooper-black, .font-cooper-black p, .font-cooper-black p span {
    font-family: 'Cooper Black', cursive !important;
    font-weight: lighter !important;
}

.font-vag-rounded-black, .font-vag-rounded-black p, .font-vag-rounded-black p span {
    font-family: 'Vag Rounded Black', cursive !important;
    font-weight: lighter !important;
}

.font-vag-rounded-bold, .font-vag-rounded-bold p, .font-vag-rounded-bold p span {
    font-family: 'Vag Rounded Bold', cursive !important;
    font-weight: lighter !important;
}

.font-vag-rounded-light, .font-vag-rounded-light p, .font-vag-rounded-light p span {
    font-family: 'Vag Rounded Light', cursive !important;
}

.font-vag-rounded-thin, .font-vag-rounded-thin p, .font-vag-rounded-thin p span {
    font-family: 'Vag Rounded Thin', cursive !important;
}
