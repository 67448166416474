	/*
  	Flaticon icon font: Flaticon
  	Creation date: 03/03/2020 09:00
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("Flaticon.eot");
  src: url("Flaticon-1.eot") format("embedded-opentype"),
       url("Flaticon.woff2") format("woff2"),
       url("Flaticon.woff") format("woff"),
       url("Flaticon.ttf") format("truetype"),
       url("Flaticon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("Flaticon.svg") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-music-note:before { content: "\f100"; }
.flaticon-radio:before { content: "\f101"; }
.flaticon-sound-faders:before { content: "\f102"; }
.flaticon-mike:before { content: "\f103"; }
.flaticon-play:before { content: "\f104"; }
.flaticon-quality:before { content: "\f105"; }
.flaticon-sketch:before { content: "\f106"; }
.flaticon-database-security:before { content: "\f107"; }
.flaticon-add-user:before { content: "\f108"; }
.flaticon-play-button:before { content: "\f109"; }